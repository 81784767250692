import HomeIcon from '@/components/icon/icons/navbar/Home-icon.vue'
import AgreementsIcon from '@/components/icon/icons/navbar/Agreements-icon.vue'
import TasksIcon from '@/components/icon/icons/navbar/Tasks-icon.vue'
import ContractorIcon from '@/components/icon/icons/navbar/Contractor-icon.vue'
import EmployeesIcon from '@/components/icon/icons/navbar/Employees-icon.vue'
import CorrespondenceIcon from '@/components/icon/icons/navbar/Correspondence-icon.vue'
import AdministrationIcon from '@/components/icon/icons/navbar/Administration-icon.vue'
import type { MenuItem } from 'best-modules/components/sidebar-menu/types'
import { ADMIN, AUDITOR, CLERKSHIP, CORRESPONDENT, USER } from '@/utils/roles'

const menuItems: MenuItem[] = [
  {
    label: 'Головна',
    icon: HomeIcon,
    to: { name: 'home' },
  },
  {
    label: 'Підписання',
    icon: 'mdi-file-sign',
    access: user => {
      return [ADMIN, USER, CORRESPONDENT, CLERKSHIP, AUDITOR].includes(
        user.roleId
      )
    },
    children: [
      {
        label: 'Чернетки',
        to: { name: 'document-signature-notes' },
      },
      {
        label: 'Поточні',
        to: { name: 'document-signature-all' },
      },
      {
        label: 'Підписані',
        to: { name: 'document-signature-signed' },
      },
    ],
  },
  {
    label: 'Візування',
    icon: 'mdi-file-check',
    access: user => {
      return [ADMIN, USER, CORRESPONDENT, CLERKSHIP, AUDITOR].includes(
        user.roleId
      )
    },
    children: [
      {
        label: 'Нові',
        to: { name: 'document-agreement-new' },
      },
      {
        label: 'Доопрацювання',
        to: { name: 'document-agreement-revision' },
      },
      {
        label: 'На візуванні',
        to: { name: 'document-agreement-me' },
      },
      {
        label: 'Завізовано',
        to: { name: 'document-agreement-agreed' },
      },
    ],
  },
  {
    label: 'Ознайомлення',
    icon: 'mdi-file-eye',
    access: user => {
      return [ADMIN, USER, CORRESPONDENT, CLERKSHIP, AUDITOR].includes(
        user.roleId
      )
    },
    children: [
      {
        label: 'Мені на ознайомлення',
        to: { name: 'document-familiarization-me' },
      },
      {
        label: 'Ознайомлені мною',
        to: { name: 'document-familiarization-familiarized' },
      },
    ],
  },
  {
    label: 'Накази',
    icon: AgreementsIcon,
    to: { name: 'order-list' },
    access: user =>
      [ADMIN, USER, CORRESPONDENT, CLERKSHIP, AUDITOR].includes(user.roleId),
  },
  {
    label: 'Мої завдання',
    icon: TasksIcon,
    access: user => {
      return [ADMIN, USER, CORRESPONDENT, CLERKSHIP, AUDITOR].includes(
        user.roleId
      )
    },
    children: [
      {
        label: 'Вхідні завдання',
        to: { name: 'incoming-tasks' },
      },
      {
        label: 'Вихідні завдання',
        to: { name: 'outgoing-tasks' },
      },
      {
        label: 'Контролюючі',
        to: { name: 'control-tasks' },
      },
      {
        label: 'Наглядачі',
        to: { name: 'observer-tasks' },
      },
    ],
  },
  {
    label: 'Контрагенти',
    icon: ContractorIcon,
    to: { name: 'contractors' },
    access: user => {
      return [ADMIN, USER, CORRESPONDENT, CLERKSHIP].includes(user.roleId)
    },
  },
  {
    label: 'Співробітники',
    icon: EmployeesIcon,
    access: user => {
      return [ADMIN, USER, CORRESPONDENT, CLERKSHIP, AUDITOR].includes(
        user.roleId
      )
    },
    children: [
      {
        label: 'Користувачі',
        to: { name: 'users' },
      },
      {
        label: 'Структура компанії',
        to: { name: 'company-structure' },
      },
      {
        label: 'Дні народження',
        to: { name: 'user-birthdays' },
      },
    ],
  },
  {
    label: 'Адміністрування',
    icon: AdministrationIcon,
    access: user => [ADMIN].includes(user.roleId),
    children: [
      {
        label: 'Маршрутизація документів',
        to: { name: 'template-routing-document' },
      },
    ],
  },
  {
    label: 'Кореспонденція',
    icon: CorrespondenceIcon,
    to: { name: 'correspondence-list' },
    access: user =>
      [ADMIN, CORRESPONDENT, CLERKSHIP, AUDITOR].includes(user.roleId),
  },
]

export { menuItems }
